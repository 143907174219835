<rowa-topbar
	[appProperties]="{ name: (isDemo ? 'Demo ' : '') + pharmacyName }"
	logoImage="assets/images/rowa-puc-logo.svg"
>
	@if (inactivityEnabled$ | async) {
		<div class="flex self-center pr-5">
			{{ inactivityTime$ | async }}
			<app-icons
				iconName="faInfo"
				class="pl-2"
				[appTooltip]="
					'PHARMACYSETTINGS.SOFTWARESETTINGS.INACTIVITY.INFO'
						| translate
				"
			/>
		</div>
	}
	<rowa-profile-menu
		[userProfile]="userProfile"
		#userPopup="rowaCallPopup"
		data-cy="userProfileDd"
		[rowaCallPopup]
	>
		<div
			class="absolute z-50 w-56 overflow-hidden bg-white rounded-lg shadow right-4 top-16"
			*rowaTemplate
			[@FadeDownUp]="{ value: userPopup.isOpen }"
		>
			<div>
				<div
					class="flex gap-x-2 items-center px-2 mb-1 w-56 h-8 cursor-pointer hover:bg-[#f3fbff] text-sm"
					[routerLink]="'profile'"
					(click)="openUserProfile(); userPopup.close()"
				>
					<rowa-icon name="faUser" />
					<div
						class="h-4 font-normal leading-[normal] tracking-normal"
					>
						{{ 'TOPNAV.USER.MENU.PROFILE' | translate }}
					</div>
				</div>
				<div
					class="flex gap-x-2 items-center px-2 mb-1 w-56 h-8 cursor-pointer hover:bg-[#f3fbff]"
					(click)="openPartnersPage(); userPopup.close()"
				>
					<rowa-icon name="faPeopleGroup" />
					<div
						class="h-4 font-normal leading-[normal] tracking-normal"
					>
						{{ 'TOPNAV.USER.MENU.PARTNERS' | translate }}
					</div>
				</div>
				<div
					*demoDisplay="canRedirectToNormal$ | async"
					class="flex gap-x-2 items-center px-2 mb-1 w-56 h-8 cursor-pointer hover:bg-[#f3fbff]"
					(click)="switchToNormal(); userPopup.close()"
				>
					<rowa-icon name="rowatraining" />
					<div
						class="h-4 font-normal leading-[normal] tracking-normal"
					>
						{{ 'TOPNAV.USER.MENU.NORMAL' | translate }}
					</div>
				</div>
				<div
					*normalDisplay
					class="flex gap-x-2 items-center px-2 mb-1 w-56 h-8 cursor-pointer hover:bg-[#f3fbff]"
					(click)="switchToDemo(); userPopup.close()"
				>
					<rowa-icon name="rowatraining" />
					<div
						class="h-4 font-normal leading-[normal] tracking-normal"
					>
						{{ 'TOPNAV.USER.MENU.DEMO' | translate }}
					</div>
				</div>
				<div
					class="flex gap-x-2 items-center px-2 mb-1 w-56 h-8 cursor-pointer hover:bg-[#f3fbff]"
					(click)="gotoTrainingRowa()"
				>
					<rowa-icon
						class="rowa-profile-user-menu__item-icon"
						name="faLinesLeaning"
					>
					</rowa-icon>
					<div class="rowa-profile-user-menu__item-label">
						{{ 'TOPNAV.USER.MENU.TRAINING' | translate }}
					</div>
				</div>
				<div
					class="flex gap-x-2 items-center px-2 w-56 h-8 cursor-pointer hover:bg-[#f3fbff] mb-0"
					(click)="doLogout(); userPopup.close()"
				>
					<rowa-icon name="faRightFromBracket" />
					<div
						class="h-4 font-normal leading-[normal] tracking-normal"
					>
						{{ 'TOPNAV.USER.MENU.LOGOUT' | translate }}
					</div>
				</div>
			</div>
		</div>
	</rowa-profile-menu>
</rowa-topbar>
