import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, Subject, takeWhile } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InactivityService {
	inactivityTime: number = 300;
	min: number;
	sec: number;

	timer = new Subject<number>();
	inactivityTimer = this.timer.pipe(
		map((timer) => {
			if (timer === 0) {
				return this.reset();
			}

			if (this.sec === 0) {
				this.min -= 1;
				this.sec = 59;
			} else {
				this.sec -= 1;
			}

			return `${this.min.toString().padStart(2, '0')}:${this.sec.toString().padStart(2, '0')}`;
		}),
		distinctUntilChanged(),
		takeWhile((_) => this.min >= 0 && this.sec >= 0),
	);

	reset() {
		this.sec = this.inactivityTime > 59 ? 0 : this.inactivityTime;
		this.min = +(this.inactivityTime / 60).toFixed();
		return `${this.min.toString().padStart(2, '0')}:${this.sec.toString().padStart(2, '0')}`;
	}
}
