import {
	CurrencyPipe,
	DecimalPipe,
	PercentPipe,
	registerLocaleData,
} from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptors,
	withInterceptorsFromDi,
} from '@angular/common/http';
import localeARAE from '@angular/common/locales/ar-AE';
import localeDA from '@angular/common/locales/da';
import localeDE from '@angular/common/locales/de';
import localeDEAT from '@angular/common/locales/de-AT';
import localeDECH from '@angular/common/locales/de-CH';
import localeEN from '@angular/common/locales/en';
import localeENAU from '@angular/common/locales/en-AU';
import localeENGB from '@angular/common/locales/en-GB';
import localeES from '@angular/common/locales/es';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeNL from '@angular/common/locales/nl';
import localeSV from '@angular/common/locales/sv';
import localeTR from '@angular/common/locales/tr';
import {
	APP_INITIALIZER,
	ApplicationConfig,
	ErrorHandler,
	importProvidersFrom,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withViewTransitions } from '@angular/router';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalInterceptor,
	MsalModule,
	MsalService,
} from '@azure/msal-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RowaCoreModule, RowaHttpErrorInterceptor } from '@rowa/core';
import { RowaUiModule } from '@rowa/ui';
import { customHttpInterceptor } from 'src/app/core/interceptors/custom-http.interceptor';
import {
	CensorCityPipe,
	CensorEmailPipe,
	CensorNamePipe,
	CensorPhonePipe,
	CensorPostalCodePipe,
	CensorStreetPipe,
} from 'src/app/core/pipes/censor.pipe';
import { PrintLengthPipe } from 'src/app/core/pipes/print-length.pipe';
import { CommunicationLanguageTranslationService } from 'src/app/core/services/custom-translation.service';
import { DynamicLocaleService } from 'src/app/core/services/dynamic-locale.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import { HttpLoaderFactory } from 'src/environments/environment';
import { routes } from './app.routes';
import { AuthInterceptor } from './views/auth/auth.interceptor';
import {
	MSALGuardConfigFactory,
	MSALInstanceFactory,
	MSALInterceptorConfigFactory,
} from './views/auth/msal-auth-config';

registerLocaleData(localeDE);
registerLocaleData(localeEN);
registerLocaleData(localeDEAT);
registerLocaleData(localeDECH);
registerLocaleData(localeIT);
registerLocaleData(localeFR);
registerLocaleData(localeENGB);
registerLocaleData(localeENAU);
registerLocaleData(localeES);
registerLocaleData(localeNL);
registerLocaleData(localeTR);
registerLocaleData(localeARAE);
registerLocaleData(localeDA);
registerLocaleData(localeSV);

export const appCfg: ApplicationConfig = {
	providers: [
		provideRouter(routes, withViewTransitions()),
		importProvidersFrom(
			BrowserModule,
			RowaCoreModule.forRoot(),
			RowaUiModule,
			TranslateModule.forRoot({
				loader: {
					provide: TranslateLoader,
					useFactory: HttpLoaderFactory,
					deps: [HttpClient],
				},
			}),
			MsalModule,
		),
		provideAnimations(),
		CommunicationLanguageTranslationService,
		CensorNamePipe,
		CensorCityPipe,
		CensorEmailPipe,
		CensorPhonePipe,
		CensorPostalCodePipe,
		CensorStreetPipe,
		PrintLengthPipe,
		DynamicLocaleService,
		CurrencyPipe,
		DecimalPipe,
		PercentPipe,
		{
			provide: APP_INITIALIZER,
			useFactory: (swaggerService: SwaggerService) => () => {
				swaggerService.init();
			},
			deps: [SwaggerService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (languageService: LanguageService) => () => {
				languageService.init();
			},
			deps: [LanguageService],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RowaHttpErrorInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService,
		},
		provideHttpClient(
			withInterceptorsFromDi(),
			withInterceptors([customHttpInterceptor]),
		),
		MsalGuard,
		MsalService,
		MsalBroadcastService,
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory,
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory,
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
	],
};
