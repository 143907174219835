import { Component, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserService } from 'src/app/core/services/user.service';
import { RowaActivityIndicatorModule } from '../../../../../libs/ui/src/lib/activity-indicator/activity-indicator.module';
import { RowaDropdownsModule } from '../../../../../libs/ui/src/lib/dropdowns/dropdowns.module';
import { GuestNavComponent } from '../../navbar/guest-nav/guest-nav.component';
import {
	AuthService,
	ORGMODE,
	PUCAuthToken,
	PharmacyInfo,
} from '../auth.service';
import { StateService } from 'src/app/core/services/state.service';
import { Subscription, skipWhile } from 'rxjs';
import { LoggingService } from 'src/app/core/services/logging.service';
import { ButtonComponent } from 'src/app/core/components/button.component';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	standalone: true,
	imports: [
		GuestNavComponent,
		RowaActivityIndicatorModule,
		RowaDropdownsModule,
		FormsModule,
		TranslateModule,
		ButtonComponent,
	],
})
export class LoginComponent implements OnInit, OnDestroy {
	showUI: boolean = false;
	showError: boolean = false;
	errorText: string = '';
	errorDetail: string = '';

	isBusy: boolean = false;
	showLoader: boolean = true;

	pharmacies: PharmacyInfo[] = [];
	currentPharma: PharmacyInfo;
	subs: Subscription = new Subscription();

	constructor(
		private authService: AuthService,
		private router: Router,
		private userService: UserService,
		private state: StateService,
	) {}

	async ngOnInit() {
		if (this.authService.msalAquireTokenFailed) {
			this.errorText =
				'SEVERE PROBLEM: failed to aquire authentication token!';
			this.showError = true;
			this.showUI = false;
			this.authService.msalAquireTokenFailed = false;
			return;
		}

		this.subs.add(
			this.userService.isSuperAdmin
				.pipe(skipWhile((x) => x == null))
				.subscribe(async (_) => {
					try {
						await this.fetchPharmacy();
					} catch (error) {
						this.errorText =
							'SEVERE PROBLEM: cannot identify your pharmacy!';
						this.errorDetail = error;
						this.showError = true;
						this.showUI = this.showLoader = false;
					}
				}),
		);

		this.authService.init();
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	async fetchPharmacy() {
		const pharmacies = this.authService.pharmacyList;
		if (pharmacies == null || pharmacies.length === 0) {
			this.noPharmacies();
			return;
		}

		if (
			!this.state.isDemo &&
			pharmacies.filter((x) => x.organisationMode === ORGMODE.DEMO)
				.length === pharmacies.length
		) {
			this.handleDemoPharma();
			return;
		}

		this.pharmacies = this.getPharmacies(pharmacies, this.state.isDemo);

		if (this.pharmacies == null || this.pharmacies.length === 0) {
			this.noPharmacies();
			return;
		}

		if (
			this.authService.currentPharmacyId != '' &&
			this.pharmacies.find(
				(x) => x.id === this.authService.currentPharmacyId,
			) != null
		) {
			// re-select same pharmacy (probably token expired)
			await this.selectPharmacy(this.authService.currentPharmacyId);
			return;
		} else {
			if (this.pharmacies.length === 1) {
				// only one pharmacy -> select automatically
				await this.selectPharmacy(this.pharmacies[0].id);
				return;
			}
		}

		this.currentPharma = this.pharmacies[0];
		this.showUI = true;
		this.showError = this.showLoader = false;
	}

	getPharmacies(pharmacies, isDemo: Boolean) {
		if (isDemo) {
			return pharmacies;
		}

		return pharmacies.filter(
			(x) => x.organisationMode === ORGMODE.DEMO_AND_STANDARD,
		);
	}

	noPharmacies() {
		this.errorText = 'You have no pharmacy assigned to your user !';
		this.showError = true;
		this.showUI = this.showLoader = false;
	}

	handleDemoPharma() {
		if (isDevMode()) {
			alert('ng serve -c demo || npm run demo');
		} else {
			if (window.location.href.indexOf('staging') > -1) {
				window.open(
					window.location.href.replaceAll('staging', 'demo-staging'),
					'_self',
				);
			} else {
				window.open(
					window.location.href.replaceAll('pickup', 'demo.pickup'),
					'_self',
				);
			}
		}
	}

	async selectPharmacy(pharmacyId: string) {
		await this.authService.switchPharmacy(pharmacyId);
		this.isBusy = true;
		this.subs.add(
			this.authService.getPUCToken().subscribe({
				next: (response: PUCAuthToken) => {
					this.router.navigate(['orders']);
				},
				error: (error) => {
					this.errorText =
						'SEVERE PROBLEM: unable to retrieve authorization token for the selected pharmacy';
					this.showError = true;
					this.isBusy = this.showUI = false;
				},
			}),
		);
	}

	tryAgain() {
		location.reload();
	}

	doLogout() {
		this.router.navigate(['logout']);
	}
}
