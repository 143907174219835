import { ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
	BehaviorSubject,
	EMPTY,
	fromEvent,
	merge,
	Observable,
	skipWhile,
	Subject,
	Subscription,
	switchMap,
	take,
	tap,
	timer,
} from 'rxjs';
import { AuthService } from 'src/app/views/auth/auth.service';
import { InactivityService } from './inactivity.service';

@Component({
	selector: 'app-inactivity',
	standalone: true,
	imports: [],
	template: ``,
	styles: ``,
})
export class InactivityComponent implements OnDestroy {
	private events: { element: Document | Window; event: string }[] = [
		{ element: document, event: 'click' },
		{ element: document, event: 'wheel' },
		{ element: document, event: 'scroll' },
		{ element: document, event: 'mousemove' },
		{ element: document, event: 'keyup' },
		{ element: window, event: 'resize' },
		{ element: window, event: 'scroll' },
		{ element: window, event: 'mousemove' },
	];
	start$ = new Subject<void>();

	private obs$ = merge(
		...this.events.map((x) => fromEvent(x.element, x.event)),
		this.start$,
	);

	timeLapsedSinceInactivity: number;
	subs = new Subscription();

	constructor(
		private ngZone: NgZone,
		private authSvc: AuthService,
		private msalSvc: MsalService,
		private route: Router,
		private inactivitySvc: InactivityService,
	) {
		this.start();
	}
	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	start() {
		const _ = this.inactivitySvc.reset();

		this.ngZone.runOutsideAngular(() => {
			this.subs.add(
				this.obs$
					.pipe(
						switchMap((ev) =>
							timer(0, 1000).pipe(
								take(this.inactivitySvc.inactivityTime),
							),
						),
						tap((val) => {
							this.inactivitySvc.timer.next(val);
						}),
						skipWhile((x) => {
							this.timeLapsedSinceInactivity = x;
							return x != this.inactivitySvc.inactivityTime - 1;
						}),
					)
					.subscribe((x) => {
						this.subs.unsubscribe();
						this.doLogout();
					}),
			);
		});

		this.start$.next();
	}

	pauseWhileInactive<T>(
		isActive$: Observable<boolean>,
	): (source$: Observable<T>) => Observable<T> {
		return (source$) =>
			source$.pipe(
				switchMap(() =>
					isActive$.pipe(
						switchMap((isOn) => (isOn ? source$ : EMPTY)),
					),
				),
			);
	}

	doLogout() {
		this.authSvc.logout();
		this.msalSvc.logoutRedirect();

		setTimeout(() => {
			this.route.navigate(['']);
		}, 500);
	}
}
